import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import { Analytics } from '@vercel/analytics/react';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <React.StrictMode>
      <App />
      <Analytics />
    </React.StrictMode>
  </HelmetProvider>
);
